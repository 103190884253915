<template>
  <router-link to="/categories">
    <div class="page">
      <div class="title">
        <img src="~@/assets/img/wonder_wall.svg" />
      </div>
      <div class="sneaker">
        <img src="~@/assets/img/sneaker_big.png" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Vue from 'vue';
import IdleVue from 'idle-vue';

const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  idleTime: 60*1000,
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'touchmove']
}
 
Vue.use(IdleVue, options);

export default {
  name: "Home",
  onIdle() {
    this.$router.push('/');
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 40.72vw;
  margin: 4vh auto;
  img {
    width: 100%;
    height: auto;
  }
}

.sneaker {
  width: 55.93vw;
  img {
    width: 100%;
    height: auto;
  }
}
</style>